import * as React from "react";
import App from "./components/App";
import { checkLanguage } from "./classes/languages";
import Progress from "./components/Progress";
import { FluentProvider, webLightTheme, webDarkTheme } from "@fluentui/react-components";
import ReactDOM from "react-dom";
import { useState, useEffect } from "react";

/* global document, Office, module, console */

const Root = () => {
  const [language, setLanguage] = useState(null);
  const [fluentTheme, setFluentTheme] = useState(webLightTheme);

  useEffect(() => {
    Office.onReady(() => {
      console.log("Office.onReady()");
      const officeTheme = Office.context.officeTheme;
      if (officeTheme.bodyBackgroundColor === "#212121") {
        setFluentTheme(webDarkTheme);
      }
      const localLanguage = Office.context.displayLanguage;
      console.log("localLanguage: " + localLanguage);
      const language = checkLanguage(localLanguage);
      setLanguage(language);
    });
  }, []);

  if (!language) {
    console.log("render Progress");
    return <Progress message={"Loading ..."} />;
  }

  console.log("render App");
  return (
    <FluentProvider theme={fluentTheme}>
      <App language={language} />
    </FluentProvider>
  );
};

Office.onReady(() => {
  const rootElement = document.getElementById("container"); // root
  if (rootElement) {
    console.log("render root");
    ReactDOM.render(<Root />, rootElement);
  }

  if (module.hot) {
    module.hot.accept("./components/App", () => {
      console.log("module.hot.accept()");
    });
  }
});
