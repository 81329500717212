import * as React from "react";
import { makeStyles, Text } from "@fluentui/react-components";

/* global */

const useStyles = makeStyles({
  footer: {
    position: "fixed",
    bottom: "10px",
    left: 0,
    paddingBottom: "10px",
    width: "100%",
    textAlign: "center",
  },
});

const Footer = () => {
  const styles = useStyles();
  return (
    <>
      <footer className={styles.footer}>
        <Text variant="medium">Defense Team</Text>
      </footer>
    </>
  );
};

export default Footer;
