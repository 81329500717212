import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Button,
  Image,
  Text,
  Textarea,
  RadioGroup,
  Radio,
  Label,
  Title3,
  Body1,
} from "@fluentui/react-components";
import { CheckmarkCircle24Filled, DismissCircle24Filled } from "@fluentui/react-icons";
import Progress from "./Progress";
import Footer from "./Footer";
import { callApi } from "../classes/callApi";
import ntsRlwcImage from "./../../../assets/nts-rlwc.png";

/* global Office console */

const useStyles = makeStyles({
  div1: {
    textAlign: "center",
    margin: 10,
    minHeight: "100vh",
  },
  div2: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
  },
  textarea: {
    width: "100%",
    height: "300px",
    margin: "10px",
    boxSizing: "border-box",
    // paddingBottom: "15px",
  },
  divCounter: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    fontSize: "12px",
    color: "#888",
  },
});

const Menu = (props) => {
  const { language, token } = props;
  const styles = useStyles();

  const [clicked, setClicked] = useState(false);
  const [comment, setComment] = useState("");
  const [counter, setCounter] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("UploadStatus UseEffect: " + uploadStatus);
  }, [uploadStatus]);

  const sendEmail = async () => {
    resetHooks();
    setIsLoading(true);
    const timer = startTimeoutTimer(30000); // unit: ms
    const itemId = Office.context.mailbox.convertToRestId(
      Office.context.mailbox.item.itemId,
      Office.MailboxEnums.RestVersion.v2_0
    );
    const payload = JSON.stringify({
      comment: comment,
      clicked: clicked,
      itemId: itemId,
    });
    console.log("Payload: " + payload);
    const uploaded = await callApi("/api/postEmlFile", token, payload);
    console.log("Call API: uploaded: " + uploaded);
    setUploadStatus(uploaded);
    clearTimeout(timer);
    setIsLoading(false);
  };

  const startTimeoutTimer = (ms) => {
    return setTimeout(() => {
      setUploadStatus(false);
      setIsLoading(false);
    }, ms);
  };

  const resetHooks = () => {
    setUploadStatus(null);
  };

  return (
    <div className={styles.div1}>
      <div style={{ textAlign: "center" }}>
        <Image src={ntsRlwcImage} alt="logo" style={{ margin: "8px auto", maxWidth: 250, display: "block" }} />
      </div>
      {(uploadStatus === false || uploadStatus === null) && (
        <>
          <Title3 style={{ padding: "8px" }}>{language.heading}</Title3>
          <br />
          <Body1>{language.description}</Body1>
          <div style={{ position: "relative", width: "80%", margin: "8px auto" }}>
            <Textarea
              placeholder={language.txtComment}
              value={comment}
              minHeight="300px"
              maxHeight="300px"
              resize="none"
              onChange={(event) => {
                if (event.target.value.length <= 500) {
                  setComment(event.target.value);
                  setCounter(event.target.value.length);
                }
              }}
              className={styles.textarea}
            />
            <div className={styles.divCounter}>{counter}/500</div>
          </div>
          <Label>{language.alreadyClicked}</Label>
          <RadioGroup
            value={clicked ? "true" : "false"}
            layout="horizontal"
            required
            onChange={(event) => {
              setClicked(event.target.value === "true");
            }}
            style={{ display: "block" }}
          >
            <Radio value="true" label={language.yes} />
            <Radio value="false" label={language.no} />
          </RadioGroup>
          <Button appearance="primary" onClick={sendEmail} disabled={isLoading}>
            {language.btnSend}
          </Button>
        </>
      )}
      {isLoading && <Progress message={language.sendingMsg} />}
      {uploadStatus && uploadStatus !== null && (
        <div className={styles.div2}>
          {uploadStatus ? (
            <>
              <CheckmarkCircle24Filled primaryFill="green" />
              <Text>{language.statusSuccess}</Text>
            </>
          ) : (
            <>
              <DismissCircle24Filled primaryFill="red" />
              <Text>{language.statusError}</Text>
            </>
          )}
        </div>
      )}
      <Footer />
    </div>
  );
};

Menu.propTypes = {
  language: PropTypes.object,
  token: PropTypes.string,
};

export default Menu;
