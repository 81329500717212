export const getUserAccessToken = async () => {
  try {
    const ssoOptions = {
      allowSignInPrompt: false, // true for testing
      allowConsentPrompt: false, // true for testing
      forMSGraphAccess: true,
    };
    const options = JSON.parse(JSON.stringify(ssoOptions));
    const accessToken = await Office.auth.getAccessToken(options);
    return accessToken;
  } catch (exception) {
    if (exception.code === 13003) {
      console.log(
        "SSO is not supported for domain user accounts, only Microsoft 365 Education or work account, or a Microsoft account."
      );
    } else if (exception.code === 13001) {
      console.log("Safari is not supported!");
    } else {
      console.log("Could not get the access token.\nError: " + exception.message);
    }
    return false;
  }
};
