import * as React from "react";
import PropTypes from "prop-types";
import Footer from "./Footer";
import { makeStyles, ErrorCircle24Filled, Box, Container, Text } from "@fluentui/react-components";

/* global console */

const useStyles = makeStyles({
  box1: {
    marginTop: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  box2: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  message: {
    color: "#f44336",
  },
});

const Error = (props) => {
  const { message } = props;
  const styles = useStyles();
  console.log("message: " + message);

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box className={styles.box1}>
          <Box className={styles.box2}>
            <Text variant="small" className={styles.message}>
              {message}
            </Text>
            <ErrorCircle24Filled className={styles.message} />
          </Box>
        </Box>
        <Footer />
      </Container>
    </>
  );
};

Error.propTypes = {
  message: PropTypes.string,
};

export default Error;
