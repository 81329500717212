import * as React from "react";
import { callApi } from "../classes/callApi";
import Error from "./Error";
import { getUserAccessToken } from "../classes/getUserAccessToken";
import Menu from "./Menu";
import Progress from "./Progress";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

/* global console */

const App = (props) => {
  const { language } = props;
  const progress = language.progress;
  const error = language.error;
  let isFirstRun = true;

  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [token, setToken] = useState(""); // Use state for the token

  useEffect(() => {
    const initialize = async () => {
      try {
        const fetchedToken = await getUserAccessToken();
        if (fetchedToken !== "") {
          setToken(fetchedToken);
          const authorized = await callApi("/api/authorize", fetchedToken, false);
          console.log("Call API authorized: " + authorized);
          setIsAuthorized(authorized);
        } else {
          console.log("Failed to retrieve the token!");
          setIsAuthorized(false);
        }
      } catch (err) {
        console.log("Failed to authorize! error: " + err);
        setIsAuthorized(false);
      }
      setLoading(false);
      console.log("Loading state set to false");
    };
    if (isFirstRun) {
      isFirstRun = false;
      initialize();
    }
  }, []);

  console.log(loading ? "render Progress" : isAuthorized === false ? "render Error" : "render Menu");

  return loading ? (
    <Progress message={progress} />
  ) : isAuthorized === false ? (
    <Error message={error} />
  ) : (
    <Menu language={language} token={token} />
  );
};

App.propTypes = {
  language: PropTypes.object,
};

export default App;
